import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';
import Modal from 'react-modal';
import SignatureCanvas from 'react-signature-canvas';
import verifyContractService from './services/verify';
import "./Sign.css"
import styled from 'styled-components';
import backgroundImage from './assets/auth-bg.jpg'; 
import FooterLog from './FooterLog';

const Container = styled.div`
    width: 100%; 
    height: 100%;
    position:relative;
    background:white;
    display: flex;
    flex-direction: column;
`

const ContainerLogin = styled.div`
    width:100%;
    display:flex;
    flex-direction:column;
    align-items:center;
    height:100%;
    position: relative;
    margin-bottom:2%;
    background-color:#09156b;
    @media (max-width: 900px) {
    }
`

const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
`;

const Text = styled.p`
    margin:0;
    padding-top:10px;
    font-size:1.2rem;
    font-weight:bold;
    color:white;
    padding-left:30px;
    
`

const ContainerTexts = styled.div`
  width: 100%; 
  display:flex;
  justify-content:center;
  height:15vh;
  flex-direction: column;
    
`

const Text2 = styled.p`
    font-size:1rem;
    color:white;
    padding-left:30px;
    
`
const Text3 = styled.p`
    font-size:1.5rem;
    color:#09156b;
    font-weight:bold;
    @media (max-width: 900px) {
      font-size:1.1rem;
     }
     @media (max-width: 400px) {
      font-size:0.8rem;
     }
    
`
const DivScroll = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  width:100%;
  flex-direction:column;
  margin-top:10px;
`

const ButtSign = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  width:100%;
  flex-direction:row;
  gap:20px;
  @media (max-width: 900px) {
    margin-bottom:5%;
  }
`
const BuScroll = styled.div`
    background:white;
    padding:10px;
    font-size:0.9rem;
    border: 1px solid #09156b;
    cursor: pointer;
    border-radius:10px;  
    display:flex;
    justify-content:center;
    align-items:center;
    color:#09156b;
    width:150px;
`

const BuFermer = styled.div`
    background:white;
    padding:10px;
    font-size:0.9rem;
    border: 1px solid #09156b;
    cursor: pointer;
    border-radius:10px;  
    display:flex;
    justify-content:center;
    align-items:center;
    color:#09156b;
    width:150px;
    @media (max-width: 400px) {
      width:110px;
      font-size:0.8rem;
    }
`
const BuClose = styled.button`
    background:#09156b;
    padding:10px;
    display:flex;
    font-size:0.9rem;
    justify-content:center;
    align-items:center;
    color:white;
    border: none;
    border-radius:10px;  
    cursor: pointer;  
    width:150px;
`

const DivSignature = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  width:40%;
  overflow:hidden;
  height:30vh;
  flex-direction:column;
  border-radius:20px;
  margin-top:10px;
  margin-bottom:10px;
  border:1px solid #09156b;
  @media (max-width: 900px) {
    width:80%;
    height:20vh;
   }
`


const SignContract: React.FC = () => {
  const { clientId, token, userId } = useParams<{ clientId: string, token: string , userId: string}>();
  const [contractUrl, setContractUrl] = useState(null);
  const [name,setName] = useState('')
  const [error, setError] = useState(false);
  const [isSigned, setIsSigned] = useState(false);
  const sigCanvas = useRef<SignatureCanvas>(null);
  const viewerRef = useRef<HTMLDivElement>(null);
  const [loaderSignContract, setLoaderSignContract] = useState<boolean>(false)

  const verifyContractUser = async () => {
    try{
        if(!clientId || !token || !userId) return
        const response = await verifyContractService.verifyContract(clientId, userId, token)
        if(!response) {
            alert('Lien expirée')
            setError(true);
            return;
        }
        setContractUrl(response.link)
        setName(response.name)
    }
    catch(e){
        console.log(e)
        return
    }
       
  }

  useEffect(() => {
    if (sigCanvas.current) {
      const canvas = sigCanvas.current.getCanvas();
      canvas.width = canvas.offsetWidth;
      canvas.height = canvas.offsetHeight;
    }
  }, [sigCanvas]);

  useEffect(() => {
    //console.log(clientId, token, clientId)
    if(clientId && token && userId){
        verifyContractUser()
    }     
  }, [clientId, token, clientId]);

  const closeModal = () => {
    setError(false);
    window.location.replace('https://www.google.com')
  };

  useEffect(()=>{
    if(isSigned){
      window.location.href  = '/'
    }
  },[isSigned])

  const scrollToEnd = () => {
    if (viewerRef.current) {
      viewerRef.current.scrollTop = viewerRef.current.scrollHeight;
    }
  }

  const clearSignature = () => {
    if (sigCanvas.current) {
      sigCanvas.current.clear();
    }
  };

  const handleSaveSignature = async () => {
    if (sigCanvas.current) {
      setLoaderSignContract(true)
      const signatureImage = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
      try {
        const response = await verifyContractService.saveSignature(clientId!, userId!, signatureImage, token!);
        if (response) {
          setIsSigned(true);
        }
      } catch (error) {
        console.error('Error saving signature', error);
      }
      finally {
        setLoaderSignContract(false)
      }
    }
  };

  return (
    <>
    <ContainerLogin>
      <ContainerTexts>
        <Text>Bienvenue {name}.</Text>
        <Text2>Veuillez signer le contrat ci-dessous.</Text2>
      </ContainerTexts>
      {contractUrl ? (
            <Container>
                <DocViewer style={{ height: 500 }} documents={[{ uri: contractUrl! }]} pluginRenderers={DocViewerRenderers} />
                <DivScroll>
                  {/* <BuScroll onClick={scrollToEnd}>Défiler jusqu'en bas ↓</BuScroll> */}
                  <DivSignature>
                    <SignatureCanvas
                    ref={sigCanvas}
                    penColor="black"
                    canvasProps={{height: '300px', width: '300px', className: 'sigCanvas' }}/> 
                  </DivSignature>
                  <ButtSign>
                    <BuClose onClick={handleSaveSignature} disabled={loaderSignContract}>{loaderSignContract ? 'Enregistrement en cours...' : 'Signer le contrat'}</BuClose>
                    <BuClose disabled={loaderSignContract} onClick={clearSignature}>Effacer</BuClose>
                    {/* {isSigned && <p>Document signé avec succes</p>}  */}
                  </ButtSign>
                </DivScroll> 
            </Container>
      ) : (
        <p>Chargement en cours..</p>
      )}
    
      <Modal
        isOpen={error}
        onRequestClose={closeModal}
        contentLabel="Accès non valide"
        ariaHideApp={false}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            overflow: 'auto',
            display: 'flex',
            justifyContent: 'center', 
            alignItems: 'center',
          },
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            backdropFilter: 'blur(100px)',
            border: '2px solid #09156b',
            width: '100%', 
            height:'100%',
            padding: '20px',
            borderRadius: '20px', 
            display: 'flex',
            flexDirection: 'column', 
            justifyContent: 'center', 
            alignItems: 'center', 
          },
        }}
      >
     
          <Text3>Accès non valide</Text3>
          <BuFermer onClick={closeModal}>Fermer</BuFermer>
       
      </Modal>
    
      </ContainerLogin>
      <FooterLog/>
      </>
  );
};

export default SignContract;