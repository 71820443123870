import React from 'react'
import styled from 'styled-components'

const FooterContainer = styled.div`
    width:100%;
    height:4vh;
    display:flex;
    align-items:center;
    justify-content:center;
    background-color:white;
`
const TextFooter = styled.p`
    color:#09156b;
    font-size:0.7rem;

`
const FooterLog = () => {
  return (
    <FooterContainer>
        <TextFooter>
        © 2024 ANAVIE - Tous droits réservés
        </TextFooter>
    </FooterContainer>
  )
}

export default FooterLog