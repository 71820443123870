import { BrowserRouter, Routes, Route,Router } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import SignContract from './SignContract';
import Acceuil from './Acceuil';

function RouterWrapper() {
    return(
        <>
        <Routes>
            <Route path='/contract/:userId/:clientId/:token' element={<SignContract/>}/>  
            <Route path='/' element={<Acceuil/>}/>
        </Routes>
        </>
    )
}

export default RouterWrapper;