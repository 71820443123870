import React, { useEffect } from 'react'
import styled, { keyframes } from 'styled-components';
import Log from './assets/picto.png'
import backgroundImage from './assets/auth-bg.jpg'; 
import FooterLog from './FooterLog';

const ContainerLogin = styled.div`
    height:100vh;
    width:100%;
    display:flex;
    align-items:center;
    flex-direction:column;
    justify-content:center;
    position: relative;
    background: url(${backgroundImage}) no-repeat;
    background-size: cover;
    @media (max-width: 900px) {
        height:auto;
        min-height:100vh;
    }
`

const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
`;
const LoginInside = styled.div`
    display:flex;
    align-items:center;
    flex-direction:column;
    justify-content:center;
    background:white;
    border-radius:20px;
    z-index:999;
    color:black;
    width: 25%;
    height: 25%;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.5); 
    padding: 30px;
    overflow:hidden;
    @media (max-width: 900px) {
        width: 80%;
        padding-top: 70px;
        padding-bottom: 70px;
    }
    @media (max-width: 350px) {
        width: 60%;
        padding-top: 70px;
        padding-bottom: 70px;
    }
`
const Logo = styled.img`
    height:40px;
    width:40px;
`


const LogTitle = styled.h3`
    font-size:1rem;
    color:black;
    opacity:0.8;
    margin-top:5%;
    @media (max-width: 350px) {
        font-size:0.8rem;
    }
`

const Acceuil = () => {

    useEffect(()=>{
        setTimeout(()=>{
            window.location.replace('https://www.google.com')
        },2000)
    },[])
  return (
    <>
    <ContainerLogin>
        <Overlay />
        <LoginInside>
            <Logo src={Log}/>
            <LogTitle>Votre contrat a été signé avec succès !</LogTitle>
        </LoginInside>  
    </ContainerLogin> 
    <FooterLog/>
</>
  )
}

export default Acceuil