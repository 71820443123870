import axios, {AxiosResponse, AxiosError} from 'axios'

const base = process.env.REACT_APP_BACKEXTURL ||  "http://localhost:4001"

const api = axios.create({
    baseURL: `${base}/verify`,
})

const api2 = axios.create({
    baseURL: `${base}/saveContract`,
})

api.interceptors.response.use(
    async (response: AxiosResponse) => {
      // Handle response data
      return response;
    },
    async (error: AxiosError) => {
      return Promise.reject(error);
    },
);

api2.interceptors.response.use(
    async (response: AxiosResponse) => {
      // Handle response data
      return response;
    },
    async (error: AxiosError) => {
      return Promise.reject(error);
    },
);


const verifyContract = async (clientId: string, userId: string, token: string): Promise<any> => {
    try {
        if (!token) return
        const response: AxiosResponse = await api.get(`/verifyContract/${clientId}/${userId}/${token}`);

        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

const saveSignature = async (clientId: string, userId: string, signature: string, token: string): Promise<any> => {
    try {
      const response: AxiosResponse = await api2.post('/save', { clientId, signature, userId, token });
  
      return response.data;
    } catch (error) {
      console.error('Error saving signature', error);
      return null;
    }
};


const verifyContractService= {verifyContract,saveSignature}

export default verifyContractService;